.date-time-wrapper {
  min-height: 100px;
}
.date-time-wrapper .column {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
}

.date-time-wrapper .column.upper {
  padding: 0 60px;
}

.date-time-wrapper .column.lower {
  justify-content: center;
}

.date-time-wrapper span.date-day,
.date-time-wrapper span.date-fulldate {
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
}

.date-time-wrapper span.date-time {
  font-size: 34px;
  font-weight: 600;
}

@media only screen and (max-width: 1669px) {
  .date-time-wrapper .column.upper {
    padding: 0 15%;
  }
  .date-time-wrapper span.date-time {
    font-size: 30px;
  }
}

@media only screen and (max-width: 950px) {
  .date-time-wrapper span.date-time {
    font-size: 26px;
  }
}

@media only screen and (max-width: 768px) {
  .date-time-wrapper {
    min-height: auto;
  }
}

@media only screen and (max-width: 485px) {
  .date-time-wrapper span.date-day,
  .date-time-wrapper span.date-fulldate {
    font-size: 0.8rem;
  }
  .date-time-wrapper span.date-time {
    font-size: 24px;
  }
  .date-time-wrapper .column.upper {
    padding: 5px;
    width: 100%;
  }
}

@media only screen and (max-width: 393px) {
  .date-time-wrapper span.date-time {
    font-size: 21px;
  }
  .date-time-wrapper .column.upper {
    padding: 2px;
  }
}
