.navbar {
  width: 100%;
  height: 80px;
  background-color: var(--color-bg);
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.24);
  -webkit-box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.24);
  outline: 1px solid #8e8e8e;
}

.navbar a,
.navbar button {
  font-size: 14px;
  color: var(--color-lighter-text);
  padding: 0;
}

.navbar .navbar-left {
  display: flex;
  align-items: center;
  width: 50%;
}

.navbar .navbar-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
}

.navbar a.navlink {
  text-decoration: none;
  padding: 0.25rem 1.5rem;
  padding-bottom: 0;
  display: flex;
  align-items: baseline;
  width: 100%;
}

.navbar a.navlink span {
  padding: 10px 0;
  border-bottom: 2px solid transparent;
}

.navbar a.navlink.active span {
  border-bottom: 2px solid var(--color-lighter-text);
  width: 1rem;
}

.navbar a.navlink.navlink-home {
  display: flex;
  align-items: baseline;
}

.navbar a.navlink:hover {
  color: var(--color-lighter-text);
}

.navbar .navbar-icon {
  position: relative;
  left: -10px;
  top: 3px;
}

.navbar .user-panel {
  display: flex;
  align-items: center;
  padding-left: 2rem;
}

.navbar #user-panel-hidden {
  display: none;
}

.navbar .user-panel-name {
  color: #3f9eff;
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
}

.navbar .user-panel-logout {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: var(--color-secondary);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 1.2rem;
}

.navbar .user-panel-logout span {
  position: static !important;
}

.navbar .navbar-logo {
  margin-right: 2rem;
}

.navbar .navbar-logo img {
  min-width: 200px;
}

/* Mobile Navbar Icon */
.sidenav-wrapper {
  display: none;
}

/* drawer css */
.sidenav-wrapper .sidenav.opened {
  z-index: 10000;
}

/* HQ -- Light Theme Switcher Icon */
.theme-switcher {
  position: fixed;
  bottom: 40px;
  padding: 0.4rem;
  background-color: #31404a;
  width: 11.9rem;
  overflow: hidden;
}

.theme-switcher a {
  font-size: 0.9rem;
  display: flex;
  align-items: center;
}

#menu-icon {
  fill: var(--color-lighter-text);
}

#zee-logo-light {
  display: none;
}

.side-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  font-size: 10px !important;
  padding: 1rem;
  letter-spacing: 2.5px;
}

.sidenav .side-footer a {
  font-size: 10px !important;
  padding: 0;
  display: inline-block;
}

/* MEDIA QUERIES */

@media only screen and (max-width: 1130px) {
  .navbar .navbar-left {
    width: 40%;
  }
  .navbar .navbar-right {
    width: 60%;
  }
  .navbar .navbar-logo img {
    min-width: 150px;
  }
}

@media only screen and (max-width: 1000px) {
  .navbar navbar-logo {
    margin-right: 1rem !important;
  }
  .navbar .user-panel-name {
    font-size: 12px;
  }
}

@media only screen and (max-width: 768px) {
  /* Navbar Switch */
  .navbar-right {
    width: auto !important;
  }
  .navbar-right a {
    display: none !important;
  }
  .navbar .navbar-logo img {
    width: 200px;
  }
  .sidenav-wrapper {
    display: block;
  }
  .navbar .user-panel-name {
    min-width: 50px;
    max-width: 20vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .navbar .user-panel-logout {
    width: 36px;
    height: 36px;
    flex: none;
    margin-left: 10px;
  }
  /* Rest of CSS */
  .blackline {
    border: 1px solid var(--color-menu-border-dark);
  }
  .whiteline {
    border: 1px solid var(--color-menu-border-light);
  }
  .rows {
    display: flex;
    flex-direction: column;
  }
  #sidebar {
    left: -14rem;
  }

  #sidebar.opened {
    left: 0;
    border-right: 2px solid var(--color-menu-border-dark);
    outline: 2px solid var(--color-menu-border-light);
  }

  .sidenav {
    height: 100%;
    width: 12rem;
    position: fixed;
    z-index: 1;
    top: 0;
    left: -12rem;
    background-color: var(--color-bg);
    overflow-x: hidden;
    transition: left 0.5s;
    padding-top: 60px;
  }
  h4,
  h5 {
    white-space: nowrap;
  }
  @media only screen and (max-width: 426px) {
    h4,
    h5 {
      white-space: nowrap;
      font-size: 12px;
    }
  }
  .sidenav a {
    padding: 5px 0px;
    text-decoration: none;
    font-size: 21px;
    display: flex;
    align-items: baseline;
    transition: left 0.3s;
    color: var(--color-lighter-text);
    text-align: left;
    white-space: nowrap;
  }
  #sidebar .active {
    background-color: #f7931e;
  }
  .icon {
    margin: 6px 12px;
  }

  .menu_item {
    width: inherit;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin-top: 5px;
  }

  .menu_item a {
    width: 100%;
  }

  #sidebar a:hover {
    color: var(--color-lighter-text);
  }

  .menu_item:first-of-type {
    margin-top: 30px;
  }

  .menu_item:hover {
    background-color: #f7931e;
  }
  .menu_item:focus {
    background-color: #f7931e;
  }

  .sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 0;
    margin-left: 50px;
    font-size: 55px;
  }

  .sidenav .svg_embedded_img {
    position: relative;
    top: 2px;
  }

  .navbar-item {
    display: flex;
  }

  .navbar,
  .navbar-menu,
  .navbar-start,
  .navbar-end {
    display: flex;
    padding: 0;
    background-color: transparent !important;
  }

  .navbar-menu {
    flex-grow: 1;
    flex-shrink: 0;
  }
  .level-left {
    align-self: center !important;
  }

  #zee-logo {
    margin-right: 0.75rem;
  }

  /* HQ -- Hide sidebar logo on desktop */
  #sidenav-zee-logo {
    display: none !important;
  }

  /* HQ -- Fix menu icon proportions */
  .menu-icon {
    height: auto !important;
  }
}

/* HQ -- Hide logo on mobile and show in sidebar */
@media only screen and (max-width: 490px) {
  #zee-logo,
  #zee-logo-light {
    /* display: none !important; */
    width: 90px;
  }
  #sidenav-zee-logo {
    display: none !important;
    position: absolute;
    top: 20px;
    left: 10px;
    width: 80px;
  }
  .navbar .navbar-logo img {
    width: 120px;
  }
}

@media only screen and (max-width: 570px) {
  .navbar .navbar-logo {
    margin-right: 1rem;
  }
}

@media only screen and (max-width: 426px) {
  .navbar .user-panel {
    padding-left: 0;
    margin-left: -32px;
  }
  .navbar .navbar-logo img {
    min-width: auto;
    width: 120px;
  }
}
@media only screen and (max-width: 375px) {
  .navbar .navbar-logo img {
    width: 100px;
  }
}

@media only screen and (max-width: 360px) {
  .client-logo-div {
    margin-right: 0 !important;
  }
}
