#config-page .config-page-title {
  position: relative;
  top: -32px;
}

#config-page .config-back-btn {
  width: 170px;
  height: 30px;
  border: 1px solid #c2c2c2;
  border-radius: 8px;
  font-size: 12px;
  cursor: pointer;
  position: absolute;
  top: -16px;
  left: 16px;
}

#config-page .config-back-btn img {
  display: inline-block;
  margin-right: 10px;
}

#config-page .config-page-content {
  margin: 0 0 0 1rem;
  max-width: fit-content;
  padding-bottom: 6rem;
}

#config-page .columns {
  margin-right: 0;
}

#config-page .columns.remote-control-enable {
  width: auto;
  max-width: 1000px;
}

#config-page .label {
  margin: 0;
  margin-right: 20px;
  font-size: 14px;
  line-height: normal;
  text-align: left;
  white-space: nowrap;
  min-width: 110px;
}

#config-page .label.min-200 {
  min-width: 200px;
}

#config-page .input,
#config-page .input:disabled {
  background-color: transparent !important;
  border-color: #6f7281;
  max-width: 180px;
  color: #efefef;
}

#config-page .input:disabled {
  color: #858585;
}

#config-page .watt-unit {
  margin-left: 10px;
}

#config-page .config-save {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 5rem;
  margin-right: 6px;
  width: 100%;
}

#config-page .config-save p {
  margin-right: 1rem;
  word-break: break-word;
  max-width: 340px;
  text-align: right;
  font-style: italic;
}

#config-page .config-save button {
  width: 200px;
}

#config-page .config-back-btn span.home-icon-mobile,
#config-page .remote-control-enable-mobile {
  display: none;
}

.invalid-input {
  border-color: #f14668 !important;
}

/* MEDIA QUERIES */

@media only screen and (max-width: 1280px) {
  #config-page .columns.remote-control-enable {
    max-width: 715px;
  }
  #config-page .config-page-content {
    padding-bottom: 8rem;
    margin-left: 0 !important;
  }
  #config-page .config-main-label {
    display: none;
  }
  #config-page .remote-control-enable-mobile {
    display: block;
  }
  #config-page .active-mode,
  #config-page .remote-control-enable-mobile {
    margin-left: 1rem !important;
  }
}

@media only screen and (max-width: 1025px) {
  #config-page .config-save {
    margin-right: 3%;
  }
}

@media only screen and (max-width: 768px) {
  #config-page .config-page-title {
    text-align: left;
    padding-left: 12px;
    font-size: 12px;
    text-transform: uppercase;
  }
  #config-page .mapping-percent {
    margin-left: 1rem !important;
  }
  #config-page .config-page-content {
    margin-left: -8px;
  }
  #config-page .config-back-btn,
  #config-page .dash-back-btn {
    right: 20px;
    left: auto !important;
    top: -28px !important;
    width: 65px;
    height: 46px;
    padding: 8px;
  }
  #config-page .config-back-btn span.back-to-list {
    display: none;
  }
  #config-page .config-back-btn span.home-icon-mobile {
    display: inline-block;
  }
  #config-page .config-save {
    width: auto;
    margin: 10px 0 0 1.5rem;
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  #config-page .config-save p {
    margin-top: 1rem;
    word-break: normal;
    max-width: none;
    text-align: left;
  }
  #config-page .config-save button {
    width: 100%;
  }
  #config-page .title-card-wrapper {
    width: calc(100% + 2rem);
  }
}

@media only screen and (max-width: 500px) {
  #config-page .config-page-content {
    max-width: 100%;
  }
  #config-page .config-save {
    width: auto;
    margin: 10px 8px 0 1.5rem;
  }
}

@media only screen and (max-width: 425px) {
  #config-page .label.min-200 {
    font-size: 12px;
  }
}

@media only screen and (max-width: 400px) {
  #config-page .config-page-title {
    font-size: 11px;
  }
  #config-page label {
    font-size: 12px !important;
  }
  #config-page .input,
  #config-page .input:disabled {
    max-width: 170px;
    font-size: 14px;
    height: 45px;
  }
  #config-page .config-save {
    margin-left: 24px;
  }
}

/* In case you need more space for the time table */

/* @media only screen and (max-width: 375px) {
  #tabbar-wrapper-col.column {
    padding-right: 0;
  }
} */
