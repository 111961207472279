.toggler-switch-wrapper {
  width: 100%;
  color: #708090;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  text-align: center;
}

#toggler-switch {
  display: block;
}

.ToggleSwitch {
  position: relative;
  width: 5rem;
  height: 2.35rem;
  display: inline-block;
}

.ToggleSwitch.disabled {
  filter: grayscale(100%);
}

.ToggleSwitch.ToggleSwitch__rounded .Slider {
  border-radius: 15rem;
  background: #eceff1;
  border: 2px solid #a0a0a0;
}

.ToggleSwitch.ToggleSwitch__rounded .Slider:before {
  border-radius: 50%;
}

.ToggleSwitch .ToggleSwitch__wrapper {
  position: relative;
  width: 5rem;
  height: 2.35rem;
  transform: scale(0.6);
}

.ToggleSwitch .ToggleSwitch__wrapper .Slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.4s ease;
  background: transparent;
  border: 2px solid #a4a5aa;
}

.ToggleSwitch .ToggleSwitch__wrapper .Slider:before {
  width: 2.45rem;
  height: 2.45rem;
  position: absolute;
  background: #fff;
  content: "";
  margin: 0;
  padding: 0;
  top: 50%;
  left: 0.05rem;
  transform: translateY(-50%);
  transition: 0.4s;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border: 4px solid #eee;
}

.ToggleSwitch .ToggleSwitch__wrapper .Slider.disabled {
  cursor: not-allowed;
}

.ToggleSwitch .ToggleSwitch__wrapper .Slider.disabled:before {
  background: #c1bebe;
}

.ToggleSwitch .ToggleSwitch__wrapper .Slider.isChecked:before {
  left: calc(100% - 2.45rem);
  background: #1de9b6;
}
