#chartdiv2 {
  width: 100%;
  height: 100%;
}

#chartdiv2.fullscreen-nodate,
#chartdiv2.fullscreen-date {
  height: calc(100% - 92px);
}

#selectordiv.selector-div-energy {
  min-height: 40px;
}

#selectordiv .row-sml {
  display: none;
}

#selectordiv .secret-span {
  /* display: none; */
  margin-left: 2px;
}

.power-chart-rs-wrapper .group-title {
  margin-right: 10px;
  white-space: nowrap;
}

.energy-chart-rs-daterange.outlined {
  outline: #ffc107 auto 4px;
}

#selectordiv .row-lg .energy-group-by-col {
  display: flex;
}

#selectordiv .row-sml {
  display: none;
  margin-top: 10px;
}

.group-title {
  font-size: 12px;
}

/* Lastprofil 15 min Update */
#lastprofil .lastprofil-row:first-child {
  margin-top: 1.5rem;
}

#lastprofil .lastprofil-row {
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
}

#lastprofil .lastprofil-title {
  color: #363636 !important;
  min-width: 90px;
  text-align: left;
  margin-right: 1.5rem;
  font-weight: bold;
}

#lastprofil .MuiInputBase-root {
  background: #ebebeb;
  border: 1px solid #adadad;
  border-radius: 4px;
  height: 45px;
  width: 200px !important;
  justify-content: space-between;
}

#lastprofil .MuiInputBase-input {
  height: 1rem;
  width: 90px !important;
  left: 10px;
}

#lastprofil .MuiIconButton-root {
  padding: 0;
  right: 10px;
  height: 30px;
  width: 30px;
}

.MuiMultiSectionDigitalClockSection-root {
  width: 72px !important;
}

/* MEDIA QUERIES */

@media only screen and (max-width: 1790px) {
  #chartdiv2.fullscreen {
    height: calc(100% - 92px) !important;
  }
  #selectordiv .row-lg .energy-group-by-col {
    display: none;
  }
  #selectordiv .row-sml {
    display: flex;
  }
  #selectordiv #dash-energy-chart-fs-interval-custom-sel-date-err-msg {
    width: auto !important;
  }
}

@media only screen and (max-width: 1550px) {
  #selectordiv .interval-col {
    padding-right: 0;
    padding-left: 0;
  }

  #selectordiv .energy-group-by-col {
    padding-left: 0;
  }
  .energy-chart-rs-daterange {
    min-width: 35px;
  }
}

@media only screen and (max-width: 1365px) {
  #selectordiv .interval-col .power-chart-rs-wrapper {
    width: 75%;
    flex-wrap: wrap;
  }
  #chartdiv2.fullscreen-nodate {
    height: calc(100% - 92px);
  }
  #chartdiv2.fullscreen-date {
    height: calc(100% - 132px);
  }
  #chartdiv2 .group-title-from {
    margin-right: 32px !important;
  }
  .selector-div-energy .date-range-wrapper {
    margin-left: 65px !important;
  }
}

@media only screen and (max-width: 953px) {
  .selector-div-energy .date-range-wrapper {
    margin-left: 0 !important;
  }
  #chartdiv2.fullscreen-nodate,
  #chartdiv2.fullscreen-date {
    height: calc(100% - 132px);
  }
  .selector-div-energy
    .energy-group-by-wrapper
    span.energy-chart-rs-daterange:last-child {
    margin-left: 0;
  }
}

@media only screen and (max-width: 787px) {
  #selectordiv .interval-col .power-chart-rs-wrapper {
    width: 90%;
  }
}

/* @media only screen and (max-width: 768px) {
  #selectordiv.selector-div-energy {
    display: flex !important;
  }
} */
