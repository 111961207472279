#device-card-container {
  filter: blur(0px);
  -webkit-filter: blur(0px);
  transition: all 3s ease-in-out;
  max-width: 900px !important;
}

#device-card-container.app-blurred {
  filter: blur(4px);
  -webkit-filter: blur(4px);
}

.cards-coming-soon {
  display: none;
}

.cards-coming-soon.show {
  display: block;
  position: fixed;
  margin: 0 auto;
  font-size: 55px;
  top: 40%;
  width: 100%;
  color: #202020;
  background-color: rgb(247 170 30 / 90%);
  font-family: "Montserrat EB Italic", sans-serif;
}

#device_card {
  width: 250px;
  height: 380px;
  -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  align-items: center;
  justify-content: space-evenly;
  background-color: #e7e7e7;
  margin: 0 auto;
  padding: 0 2rem;
}

#device-card-row {
  padding-bottom: 4rem;
}

#device_card .device-img-container {
  display: block;
  background-color: #b1c1cd;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f7f7f7),
    to(#e7e7e7)
  );
  background-image: -webkit-linear-gradient(top, #f7f7f7, #e7e7e7);
  background-image: -moz-linear-gradient(top, #f7f7f7, #e7e7e7);
  background-image: -ms-linear-gradient(top, #f7f7f7, #e7e7e7);
  background-image: -o-linear-gradient(top, #f7f7f7, #e7e7e7);
  color: #a7a7a7;
  border-radius: 50%;
  width: 140px;
  box-shadow: 0px 3px 8px #aaa, inset 0px 2px 3px #fff;
}

#device_card .device-img-container img {
  position: relative;
  transform: scale(0.7);
}

#device_card .device-readings {
  font-size: 1.25rem;
}

#device_card .card-details-btn {
  margin-bottom: 0;
  cursor: auto;
}

/* MEDIA QUERIES */
@media only screen and (max-width: 768px) {
  #device-card-container {
    margin-top: 5rem;
    max-width: 90%;
  }
  #device-card-col {
    margin: 0 !important;
    margin-bottom: 3rem !important;
  }
  .cards-coming-soon.show {
    font-size: 35px;
    min-height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
