.amchart-wrapper {
  width: 100%;
  height: 100%;
}

#chartdiv {
  width: 100%;
  height: 100%;
}

#chartdiv.fullscreen-nodate,
#chartdiv.fullscreen-date {
  height: calc(100% - 40px);
}

.power-chart-rs-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.power-chart-input {
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 0 5px;
  width: 110px;
  height: 30px;
}

.power-chart-input:focus {
  outline: #ffc107 auto 4px;
}

.power-chart-rs-submit {
  width: 32px;
  height: 32px;
  background-color: #1de9b6;
  border: 1px solid #dbdbdb;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  margin-left: 16px;
}

.power-chart-rs-submit:active {
  box-shadow: 0px 1px #00d1b2;
  transform: translateY(2px);
}

.power-chart-rs-daterange {
  min-width: 45px;
  min-height: 35px;
  background: #ffffff;
  border: 1px solid #dbdbdb;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  color: #535b62;
  margin: 0 10px 6px 0;
  padding: 0 6px;
  white-space: nowrap;
  cursor: pointer;
  font-size: 12px !important;
}

.power-chart-rs-daterange.active {
  background-color: #3f9eff;
  color: #efefef;
}

.date-range-wrapper {
  margin-top: -6px;
}

.arrow-controls-wrapper {
  position: absolute;
  display: flex;
  top: 3px;
  right: 70px;
}

.arrow-controls-wrapper .energy-chart-rs-daterange {
  min-width: auto;
  width: 30px;
  min-height: auto;
  height: 30px;
}

#export-wrapper {
  position: absolute;
  right: 24px;
  top: 1px;
  width: 31px;
  height: 31px;
}

#export-wrapper .amcharts-amexport-menu-level-0 {
  top: 1px;
  right: 0;
  margin: 0;
}

#export-wrapper .amcharts-amexport-item:hover,
#export-wrapper .amcharts-amexport-item.active {
  background-color: #f1f1f1;
}

#export-wrapper .amcharts-amexport-item {
  background-color: #fff;
  opacity: 1;
}

#export-wrapper .amcharts-amexport-label {
  text-decoration: none !important;
  color: #535b62;
}

.amchart-wrapper .bg-fill-toggler {
  position: absolute;
  bottom: 30px;
  right: 12%;
  display: inline-block;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  text-decoration: none;
  padding: 0 5px;
}

#selectordiv {
  padding: 0 1.25rem;
}

#selectordiv .MuiInput-root {
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 0 5px;
  height: 32px;
  background-color: #fff;
  width: 120px !important;
}

#selectordiv .MuiInputBase-input {
  font-size: 12px !important;
}

#selectordiv .MuiFormControl-root .MuiInput-root.Mui-focused {
  outline: #ffc107 auto 4px;
}

#selectordiv .MuiInput-root.Mui-focused .MuiButtonBase-root {
  height: 100%;
}

#selectordiv .MuiInputBase-input {
  left: 0;
}

#selectordiv .col-1 {
  width: 190px;
}

#tooltip-top.ytd-tooltip {
  font-size: 10px;
  font-family: "Montserrat", sans-serif;
}

.group-title-from {
  margin-right: 10px;
}

#selectordiv p.rs-msg {
  font-size: 10px;
}

/* Fix series1 extra ghost bullet */
#chartdiv g[role="group"][stroke="#2196f3"]:last-child {
  visibility: hidden !important;
}

/* MEDIA QUERIES */

@media only screen and (max-width: 1550px) {
  .power-chart-rs-daterange {
    min-width: 35px;
  }
  #selectordiv .MuiInput-root {
    width: 114px !important;
  }
  #selectordiv p.rs-msg {
    font-size: 9px;
  }
}

@media only screen and (max-width: 1450px) {
  .power-chart-rs-daterange {
    font-size: 10px !important;
  }
  #selectordiv .MuiInput-root {
    width: 108px !important;
  }
  #selectordiv .MuiInputBase-input {
    font-size: 11px !important;
  }
}

@media only screen and (max-width: 1365px) {
  #selectordiv .col-1 {
    width: 25%;
  }
  #chartdiv.fullscreen-nodate {
    height: calc(100% - 40px);
  }
  #chartdiv.fullscreen-date {
    height: calc(100% - 80px);
  }
  .date-range-wrapper {
    margin-top: 4px;
  }
  .group-title-from {
    margin-right: 32px !important;
  }
}

@media only screen and (max-width: 953px) {
  #chartdiv.fullscreen-nodate,
  #chartdiv.fullscreen-date {
    height: calc(100% - 80px);
  }
  .date-range-wrapper {
    margin-top: 0;
  }
  .power-chart-rs-wrapper span:last-child {
    margin-left: 65px;
  }
  .power-chart-rs-daterange.outlined {
    margin-left: 65px;
  }
  .power-chart-rs-daterange.custom-selector {
    margin-top: 5px;
    margin-bottom: 0;
  }
  .group-title-from {
    margin-right: 10px !important;
  }
  #selectordiv .col .power-chart-rs-wrapper {
    width: 70%;
  }
  #selectordiv .date-range-wrapper {
    position: relative;
  }
  #selectordiv .col p {
    position: absolute;
    right: -105px;
  }
  #selectordiv .col-1 {
    width: auto;
  }
  .arrow-controls-wrapper {
    right: 32px;
  }
  #export-wrapper {
    right: 0;
  }
  #chartdiv .group-title-from {
    margin-right: 8px !important;
  }
  .power-chart-rs-daterange {
    min-width: 30px;
    min-height: 30px;
    font-size: 10px !important;
  }
  #selectordiv .MuiInput-root {
    height: 28px;
  }
  .power-chart-rs-submit {
    width: 28px;
    height: 28px;
  }
  .arrow-controls-wrapper {
    top: 0;
  }
  #export-wrapper {
    top: -2px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 814px) {
  #selectordiv .col .power-chart-rs-wrapper {
    width: 85%;
  }
}

@media only screen and (max-width: 768px) {
  #selectordiv .dropdown-header {
    font-size: 10px;
  }
  #selectordiv a.dropdown-item:not(.active) {
    color: #535b62;
  }
}
