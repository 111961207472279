.wrapper {
    position: relative;
    border-top: 1px solid #4A4E5C;
    display: grid;
    place-items: center;
    width: 100%; 
  }
  
  .title {
    color: var(--color-dark-text);
    background: var(--color-bg);
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 12px;
    padding: 10px;
    position: absolute;
    top: -18px;
    /* 66.11 is title text width + its padding / 2 */
    left: calc(50% - 66.11px);
  }
h3 {
  white-space: nowrap;
  font-size: 14px !important;
}
.cumulative_card{
  width: inherit;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 426px) {
  .l-item {
    margin-bottom: 0.75rem;
  }
  h3{
    font-size: 12px;
  }
  .level-item{
    margin-right: 3rem;
  }
  /* HQ -- Disable right margin for date/time on mobile */
  .date-time {
    margin-right: 0;
  }

  .cumulative_card{
    width: inherit;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}