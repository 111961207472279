/* Change color of HTML time-picker icon */
.time_table table input[type="time"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  border-radius: 4px;
  margin-right: 2px;
  opacity: 0.6;
  filter: invert(0.8);
}

.time_table table input[type="time"]::-webkit-calendar-picker-indicator:hover {
  opacity: 1;
}

.time_table_wrapper {
  transform-origin: left center;
}

.time_table .table {
  background-color: transparent !important;
  color: #6f7281;
  border-collapse: collapse;
  border-radius: 5px;
  border-style: hidden; /* hide standard table (collapsed) border */
  box-shadow: 0 0 0 1px #6f7281; /* this draws the table border  */
}

.time_table .table tbody,
.time_table .table td,
.time_table .table tfoot,
.time_table .table th,
.time_table .table thead,
.time_table .table tr {
  border-width: 1px;
  border-color: #6f7281;
}

.time_table .table td {
  border-top: none;
  border-bottom-width: 0;
  vertical-align: middle;
}

.time_table select {
  min-width: 110px;
}

.time_table .table-header {
  color: #e1e1e1;
}

.time_table .table-header-days {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.time_table .table-header-days-sub {
  border: none;
  border-top: 1px solid #6f7281;
  font-size: 10px;
}

.time_table .table-header-days-sub td {
  padding: 4px;
}

.time_table .table-header-days-sub .table-header-day {
  width: 24px;
  display: inline-block;
}

.time_table .time-days {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.time_table .time-day input {
  transform: scale(1.5);
}

.time-toggler .ToggleSwitch {
  height: 18px;
  top: -6px;
  transform: scale(0.8);
}

.form-control {
  min-width: 30px;
}

.time_table select,
.time_table .row {
  flex-wrap: nowrap !important;
}

.time_table .table-row-item img {
  margin: 0 5px;
}

.time-config .add-wrapper-time {
  width: 100%;
  padding-left: 0;
  padding-bottom: 0;
  margin-top: 3rem;
}

.add-wrapper-time .add-circle-wrapper:before,
.add-wrapper-time .add-circle-wrapper:after {
  height: 1px;
  background-color: #e1e1e1;
}

.time_table table .time-input,
.time_table table .time-input:disabled,
.time_table table .time-input:focus {
  background-color: transparent;
  box-shadow: none;
  border: none;
  color: inherit;
  outline: none;
  text-align: center;
}

.time_table table .time-days .time-days {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.time_table table .time-days .time-day {
  padding: 0;
  border: none;
}

.time_table table .device-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 16px;
  border: 0;
  height: 100%;
}

.time_table table .device-controls img {
  cursor: pointer;
  padding: 5px;
  height: 100%;
}

/* Material UI Date Picker Overrides */

.MuiInput-root {
  width: 140px !important;
  font-family: "Montserrat", sans-serif !important;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.MuiInputBase-input {
  padding: 0 !important;
  color: #6f7281 !important;
  font-size: 14px !important;
  width: 80px !important;
  position: relative;
  left: 4px;
}

.MuiIconButton-root {
  padding: 10px 0 !important;
}

.MuiIconButton-root.Mui-disabled {
  visibility: hidden !important;
}

.MuiIconButton-root .MuiIconButton-label img {
  filter: invert(49%) sepia(9%) saturate(511%) hue-rotate(193deg)
    brightness(89%) contrast(92%);
}

.MuiPickersToolbar-toolbar,
.MuiPickersDay-daySelected {
  background-color: #0075ff !important;
}

.MuiSvgIcon-root {
  /* Change color of date-picker icon */
  fill: #6f7281 !important;
  transform: scale(0.7);
}

.MuiInput-underline:before,
.MuiInput-underline:after {
  border-bottom: none !important;
}

.MuiFormHelperText-root.Mui-error {
  font-family: "Montserrat", sans-serif !important;
  /* Override to display message below table */
  display: none !important;
}

.device-controls .not-allowed::before,
.device-controls .allowed::before {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background-color: #ff3e2b;
  position: absolute;
  right: -8px;
  top: -20px;
}

.device-controls .allowed::before {
  background-color: aquamarine;
}

/* MEDIA QUERIES */

@media only screen and (max-width: 1330px) {
  .device-controls .not-allowed::before,
  .device-controls .allowed::before {
    top: -22px;
    right: 2px;
  }
  .MuiInput-root {
    width: 132px !important;
  }
}

@media only screen and (max-width: 1280px) {
  .time_table .table-header {
    font-size: 10px;
  }
  .device-controls .not-allowed::before,
  .device-controls .allowed::before {
    top: -20px;
    right: -8px;
  }
  .MuiInput-root {
    width: auto !important;
  }
}

@media only screen and (max-width: 1025px) and (min-width: 769px) {
  .time_table_wrapper.m-0 {
    margin-top: -1% !important;
    margin-bottom: -1% !important;
  }
}

@media only screen and (max-width: 768px) {
  #time-desktop {
    display: none;
  }
  .time_table .table {
    height: 1px; /* Fix device controls cell height not 100% of td */
  }
  #time-mobile table .device-controls img {
    width: 36px;
  }
  #time-mobile #date-time-td {
    margin: 0 !important;
    padding: 0 !important;
    height: 60px;
  }
  #time-mobile .date-time-col {
    width: 100%;
    height: 50%;
    text-align: center;
  }
  #time-mobile .date-time-col-date {
    border-bottom: 1px solid #6f7281;
    padding-left: 40px;
  }
  #time-mobile .date-time-col-half:first-child {
    border-right: 1px solid #6f7281;
  }
  #time-mobile .date-time-col-half {
    width: 50%;
    height: 100%;
    padding: 0 2px 0 6px;
  }
  #time-mobile table tr {
    height: 100%;
  }
  #time-mobile table .device-controls {
    padding: 0;
    margin: 0 4px;
    display: block;
    height: 100%;
  }
  #time-mobile table .device-controls-wrapper {
    display: flex;
    justify-content: space-between;
    height: 100%;
  }
  .time-config .add-wrapper-time {
    margin-top: 4rem;
  }
  #time-mobile .selected-days-mobile {
    padding: 0;
    text-align: right;
  }
  #time-mobile .selected-days-mobile span {
    font-size: 9px;
    font-weight: 400;
    text-transform: uppercase;
    margin: 4px 2px;
  }
  .time-toggler {
    min-height: 66px;
  }
  .time-toggler .ToggleSwitch {
    margin-top: 4px;
  }
  .device-controls .not-allowed::before,
  .device-controls .allowed::before {
    right: 0;
    top: 5px;
  }
}

@media only screen and (max-width: 620px) {
  #time-mobile table td.time-toggler {
    padding: 0;
  }
  #time-mobile table .device-controls img {
    padding: 0 2px;
  }
  #time-mobile .table-responsive-sm {
    overflow-x: visible !important;
  }
  .date-time-col-time .MuiInputBase-input {
    width: 70px !important;
  }
  .device-controls .not-allowed::before,
  .device-controls .allowed::before {
    right: -2px;
    top: 3px;
  }
}

@media only screen and (max-width: 580px) {
  #time-mobile .table-row-item,
  #time-mobile input {
    font-size: 12px !important;
  }
  .date-time-col-time .MuiInputBase-input {
    width: 60px !important;
  }
}

@media only screen and (max-width: 530px) {
  #time-mobile .table-responsive-sm {
    overflow-x: auto !important;
  }
  .time_table .table {
    border-style: initial;
  }
  .time_table_wrapper p {
    position: relative !important;
    bottom: -1rem;
  }
  #time-mobile table .device-controls {
    padding: 0;
    margin: 0;
    display: table-cell;
  }
  #time-mobile table .device-controls-group div {
    padding: 0 1rem;
    cursor: pointer;
    position: relative;
  }
  #time-mobile table .device-controls-group span {
    position: relative;
    top: 2px;
  }
  #time-mobile table .device-controls-wrapper {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 4px 0;
    min-height: 42px;
  }
  #time-mobile table .device-controls img {
    width: 20px;
    padding: 6px 0;
    margin: 0 4px;
    position: relative;
    left: -12px;
  }
  .device-controls .not-allowed::before,
  .device-controls .allowed::before {
    top: -2px;
  }
  #time-mobile .table-row-item,
  #time-mobile input {
    font-size: 11px !important;
  }
  #time-mobile #save-icon-mobile {
    transform: scale(1.1);
    position: relative;
    bottom: 0;
  }
  #time-mobile .selected-days-mobile span:last-child {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 430px) {
  #time-mobile input {
    font-size: 10px !important;
  }
  #time-mobile .time-toggler .ToggleSwitch {
    width: 40px;
  }
  #time-mobile .time-toggler .ToggleSwitch .ToggleSwitch__wrapper {
    position: relative;
    left: -18px;
  }
}

@media only screen and (max-width: 390px) {
  #time-mobile input {
    font-size: 8px !important;
  }
  .date-time-col-time .MuiInputBase-input {
    width: 50px !important;
  }
}
