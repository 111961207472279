.sub-config {
  width: 100%;
}

.title-card-wrapper {
  display: flex;
}

.title-card {
  margin-right: 1.5rem;
  width: max-content;
  white-space: nowrap;
}

.title-card-wrapper hr {
  width: 100%;
}

.power-err-msg-mobile {
  display: none;
}

@media only screen and (max-width: 768px) {
  .power-err-msg-desktop {
    display: none;
  }
  .power-err-msg-mobile {
    display: block;
  }
}
