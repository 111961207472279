span#ripples {
  position: absolute !important;
  background-color: #fff;
  transform: translate(-50%, -50%);
  pointer-events: none;
  border-radius: 50%;
  opacity: 0.5;
  animation: rippleAnimate 0.3s linear;
}

@keyframes rippleAnimate {
  0% {
    width: 0;
    height: 0;
    opacity: 0.5;
  }
  100% {
    width: 90px;
    height: 90px;
    opacity: 0;
  }
}
