#auth-page {
  height: 100vh;
}

.auth-wrapper {
  width: 100%;
  height: 70%;
  display: flex;
  align-items: center;
}

.auth-remember {
  align-items: center;
  margin-bottom: 0 !important;
}

.zee-logo {
  padding-right: 15%;
}

.login-form {
  padding-left: 20%;
}

.divider-line {
  border: 1px solid #e1e1e1;
  height: 100%;
}

.divider-circle {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: var(--color-bg);
  border: 2px solid #e1e1e1;
  position: absolute;
  left: calc(50% - 75px);
}

.zee-icon {
  position: relative;
  left: -12px;
  transform: scale(1.15);
}

.eye-icon {
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
}

#auth-page .eye-icon {
  top: 1px;
  right: 4px;
}

#auth-page .eye-icon path {
  transform: translate(8px, 6px);
}

#auth-page .field,
#auth-page .field-body {
  width: 85%;
}

#auth-page .field.login-button-wrapper {
  margin-bottom: 0;
  padding-bottom: 0;
}

#auth-page .checkbox {
  transform: scale(1.5);
  transform-origin: top;
}

#auth-page .signup-wrapper {
  flex-direction: column;
}

#auth-page .signup {
  color: #3f9eff;
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
  text-align: left;
}

#auth-page .forgot-pw {
  font-size: 10px;
}

#auth-page .auth-msg {
  display: block;
  margin-top: 20px;
  min-height: 60px;
}

#info-popover {
  font-family: "Montserrat", sans-serif;
}

#info-popover .popover-header,
#info-popover .popover-body {
  font-size: 12px !important;
}

#info-popover .popover-header {
  font-weight: bold;
}

.info-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  /* background-color: #d5d5d5; */
  vertical-align: middle;
  margin-left: 8px;
  border: 2px solid #bbbbbb;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bolder;
  color: #bbbbbb;
}

/* Google ReCaptcha */

.grecaptcha-badge {
  visibility: hidden !important;
}

.google-recaptcha-text {
  font-size: 10px;
  margin-top: 30px;
  display: inline-block;
  text-align: left;
}

/* Direct styling not working due to Google usage of iframe which ignores external CSS */

.grecaptcha-badge {
  box-shadow: #14171a 0px 0px 3px !important;
}

.rc-anchor-normal-footer {
  background: #202b33 !important;
}

.rc-anchor-invisible-text {
  background: 1e2931 !important;
  color: #efefef !important;
  font-family: "Montserrat", sans-serif !important;
}

.app-package-version {
  position: fixed;
  bottom: 0;
  right: 1rem;
  font-size: 10px;
}

/* Splash Animation */

.splash {
  display: block;
  width: 0;
  height: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  transition: 0.8s ease;
  border-radius: 50%;
  background-color: #1de9b6;
  transition-delay: 0.25s;
}

.expanded {
  animation: expand 0.8s ease-in-out;
}

@keyframes expand {
  0% {
    height: 0;
    width: 0;
    padding-bottom: 0;
  }
  100% {
    height: auto;
    width: 1000%;
    padding-bottom: 1000%;
  }
}

@-o-keyframes expand {
  0% {
    height: 0;
    width: 0;
    padding-bottom: 0;
  }
  100% {
    height: auto;
    width: 1000%;
    padding-bottom: 1000%;
  }
}

@-moz-keyframes expand {
  0% {
    height: 0;
    width: 0;
    padding-bottom: 0;
  }
  100% {
    height: auto;
    width: 1000%;
    padding-bottom: 1000%;
  }
}

@-webkit-keyframes expand {
  0% {
    height: 0;
    width: 0;
    padding-bottom: 0;
  }
  100% {
    height: auto;
    width: 1000%;
    padding-bottom: 1000%;
  }
}

/* MEDIA QUERIES */

@media only screen and (max-width: 1199px) {
  #auth-page .field,
  #auth-page .field-body {
    width: 100%;
  }
  .zee-logo {
    padding-left: 0;
    padding-right: 10%;
  }
  .divider-circle {
    width: 120px;
    height: 120px;
    left: calc(50% - 60px);
  }
}

@media only screen and (max-width: 992px) {
  #auth-page.container {
    max-width: 94%;
  }
  #auth-page .field,
  #auth-page .field-body {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  #auth-page {
    height: auto;
    min-height: 100vh;
  }
  .auth-wrapper {
    flex-direction: column;
    height: auto;
    padding-top: 2rem;
  }
  .divider-line {
    display: none;
  }
  .divider-circle {
    width: 100px;
    height: 100px;
    left: auto;
    right: 20px;
  }
  .zee-logo {
    width: 100%;
    display: flex;
    align-items: center;
    padding-right: 0;
    padding-left: 0;
  }
  .zee-logo img {
    width: 80%;
    position: relative;
    top: 8px;
    padding-right: 1.5rem;
  }
  .login-form {
    padding-left: 0;
    width: 100%;
  }
  .login-form input.input,
  .login-button-wrapper button {
    height: 50px;
  }
  .auth-remember {
    display: flex;
  }
  .auth-remember label {
    margin-right: 1rem;
  }
  .auth-email .field-label,
  .auth-pw .field-label {
    display: flex;
  }
  .auth-email .field-label label,
  .auth-pw .field-label label {
    font-weight: normal;
  }
  .eye-icon {
    top: 14px;
  }
  #auth-page .eye-icon {
    transform: translateY(4px);
  }
  #auth-page p.auth-msg.is-success {
    padding-top: 2rem;
  }
}

@media only screen and (max-width: 540px) {
  .divider-circle {
    transform: scale(0.8);
    transform-origin: top right;
  }
}

@media only screen and (max-width: 426px) {
  .zee-logo img {
    top: 4px;
  }
  .divider-circle {
    transform: scale(0.6);
  }
  #info-popover {
    max-width: 200px;
  }
}
