#custom-modal .modal-card-title {
  text-align: left;
  font-size: 1rem;
  margin-bottom: 0;
}

#custom-modal .modal-card-body .label {
  margin-bottom: 0 !important;
}

#custom-modal .delete {
  transform: scale(1.6);
}

#custom-modal footer button {
  width: 120px;
}

#custom-modal footer button.save {
  /* background-color: var(--color-secondary); */
  color: #fff;
  margin-right: 1rem;
  border-color: #b7b7b7;
}

#custom-modal .modal-card-body .flex-start {
  min-width: 222px;
}

@media only screen and (max-width: 768px) {
  #custom-modal .modal-card {
    width: 90%;
  }
  #custom-modal .modal-card-body {
    text-align: left !important;
  }
}
