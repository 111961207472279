/* Montserrat font apply */
html,
body,
#root {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: normal;
  background-color: var(--color-bg);
  color: var(--color-dark-text);
  height: 100vh;
  overflow: hidden;
}

.custom-button {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #202b33 !important;
  float: right;
  width: 40%;
  padding: 0.75rem 0;
}

label {
  color: var(--color-dark-text) !important;
  padding-bottom: 0 !important;
}

button {
  font-size: 12px !important;
}

.input[disabled],
.select fieldset[disabled] select,
.select select[disabled],
.textarea[disabled],
fieldset[disabled] .input,
fieldset[disabled] .select select,
fieldset[disabled] .textarea {
  background-color: #adadad !important;
}

.help {
  text-align: left !important;
}

:focus :focus-within {
  outline: 0 !important;
  border: none !important;
  box-shadow: none !important;
}

/* Helper Classes */
.label-on-top {
  flex-direction: column;
  align-items: baseline;
}

.flex-it {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.input-err-msg-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  min-height: 1rem;
}

.lighter-text {
  color: var(--color-hover) !important;
}
