.light-text {
  color: var(--color-light-text) !important;
}

.totalpower-tooltip.export > .tooltip-inner {
  background-color: aquamarine;
  color: #333;
  border: 1px solid aquamarine;
}

.totalpower-tooltip.export > .tooltip-arrow::before {
  border-top-color: aquamarine;
}

.totalpower-tooltip.import .tooltip-inner {
  background-color: #e44646;
  color: #efefef;
  border: 1px solid #e44646;
}

.totalpower-tooltip.import > .tooltip-arrow::before {
  border-top-color: #e44646;
}
