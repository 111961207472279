#tabbar {
  width: 100%;
}

#tabbar label {
  font-size: 14px;
}

#tabbar .tabs {
  margin-bottom: 1rem;
}

#tabbar .tabs ul {
  border-bottom: none !important;
  padding-left: 0;
}

#tabbar .tabs li {
  border-radius: 6px;
  margin-bottom: -5px;
  margin-right: 16px;
}

#tabbar .tabs a {
  justify-content: flex-start;
  min-width: 150px;
}

#tabbar .tabs li.is-active a {
  background-color: transparent;
  border-color: transparent;
}

#tabbar .tabs.is-boxed a:hover {
  background-color: transparent !important;
  border-bottom-color: transparent !important;
}

#tabbar .icon {
  display: none;
}

#tabbar .tab-area {
  align-items: baseline;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 0 0 1rem 1rem;
}

#tabbar .custom-radio {
  visibility: hidden;
}

#tabbar .circle {
  position: absolute;
  top: 0px;
  left: 0px;
  transform: translate(0%, 10%);
  height: 25px;
  width: 25px;
  border: 3px solid var(--color-lighter-text);
  border-radius: 50%;
}

#tabbar .circle:after {
  content: "";
  height: 10px;
  width: 10px;
  top: 2px;
  left: 2px;
  display: block;
  position: absolute;
  background: #2196f3;
  transform: translate(25%, 25%);
  border-radius: 100%;
  opacity: 0;
}

#tabbar input.input.is-static {
  color: #6f7281;
  font-family: "Montserrat", sans-serif;
}

#tabbar .radio input:checked ~ .circle:after {
  opacity: 1;
}

/* HQ -- Adjust all radio/checkboxes hover color */
#tabbar .radio:hover,
#tabbar .checkbox:hover {
  color: var(--color-hover) !important;
}

.tabs li.is-active img {
  filter: brightness(0) invert(1);
}

/* MEDIA QUERIES */

@media screen and (max-width: 768px) {
  #tabbar .tabs ul {
    width: 100%;
    overflow: hidden;
  }
  #tabbar .tabs a {
    min-width: auto;
  }
}

@media screen and (max-width: 620px) {
  #tabbar .tabs li {
    margin-right: 0;
  }
  #settings-tabs label {
    font-size: 12px;
  }
}

@media screen and (max-width: 620px) {
  #tabbar {
    width: auto;
  }
  #tabbar .tabs a {
    padding-right: 4px;
  }
}

@media screen and (max-width: 500px) {
  #tabbar {
    width: 100%;
  }
  #tabbar .icon {
    display: block;
    margin: 8px 8px;
  }
  #tabbar .tabs {
    margin-left: 0;
    margin-top: 1rem;
    overflow: visible;
  }
  #tabbar .tabs ul {
    flex-direction: row;
    justify-content: center;
  }
  #tabbar .tabs li {
    margin-right: 10px;
    margin-left: 10px;
  }
  #tabbar .tabs a {
    padding: 0px;
    font-size: 8px;
  }
  #tabbar .tab-area {
    padding: 0 0 30px 15px;
  }
  #tabbar .tab-area .wrap .container {
    padding: 0;
  }
  #tabbar .name {
    padding-right: 10px;
  }
  #tabbar .icon img {
    position: relative;
    top: -2px;
  }
  #tabbar .tabs ul {
    border-bottom: 1px solid #4a4e5c !important;
    position: relative;
    left: 10px;
  }
  #tabbar .tabs li.is-active {
    background-color: #f7931e !important;
    border-color: #f7931e;
    border-bottom-color: transparent !important;
    color: white;
  }
  #tabbar .tabs li.is-active a {
    background-color: #f7931e !important;
    border-color: #f7931e;
    border-bottom-color: transparent !important;
    color: white;
  }
  .tabs li {
    border: 1px solid #4a4e5c;
    box-sizing: border-box;
    border-radius: 6px;
    margin-bottom: -5px;
    margin-right: 16px;
  }
}

@media screen and (max-width: 475px) {
  #tabbar .name {
    display: none;
  }
  #tabbar .icon {
    width: 50px;
  }
  #tabbar .icon .voltage-icon {
    top: -6px;
  }
  #tabbar .tabs ul::after {
    content: "";
    border-bottom: 3px solid #000;
    width: 10px;
    display: block;
    position: relative;
    left: 80px;
  }
}

@media screen and (max-width: 400px) {
  #tabbar .icon {
    width: 40px;
  }
}
