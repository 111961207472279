.light-text {
  color: var(--color-light-text);
}

#phase-card-wrapper {
  width: 88%;
  flex-direction: column;
  margin: 0 auto;
  padding-top: 9%;
}

#phase-card-wrapper p.bottom-title {
  width: 100%;
  height: 24px;
  display: flex;
  align-items: center;
}

#phase-card-wrapper p.active-power-reading,
#phase-card-wrapper p.volt-ampere {
  font-weight: 600;
}

/* HQ -- Style reverse energy reading and forward/reverse arrows */
.energy-arrow {
  position: relative;
  left: -7px;
}

.energy-arrow_rev {
  transform: scale(-1, 1);
}

.power-readings-mobile {
  display: none;
}

/* MEDIA QUERIES */

@media only screen and (max-width: 1600px) {
  #phase-card-wrapper {
    width: 100%;
    padding-left: 10px;
  }
  #phase-card-wrapper .active-power-reading {
    font-size: 1.1rem !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1200px) {
  .pl-4 {
    padding-left: 0.75rem !important;
  }
}

@media only screen and (max-width: 850px) {
  .phase-wrapper p.is-size-6 {
    font-size: 15px !important;
  }
  .phase-titles {
    font-size: 10px !important;
  }
  #phase-card-wrapper p.bottom-title {
    font-size: 10px !important;
    height: 22px;
  }
  #phase-card-wrapper .active-power-reading {
    font-size: 1rem !important;
  }
}

@media only screen and (max-width: 768px) {
  .phase-wrapper {
    display: flex;
    align-items: center;
  }
  .phase-titles {
    display: none !important;
  }
  .energy-arrow {
    display: none;
  }
  .pl-4 {
    padding-left: 0 !important;
    text-align: center;
  }
  #phase-card-wrapper {
    padding-top: 0;
  }
  #phase-card-wrapper .is-size-6 {
    font-size: 0.8rem !important;
    text-align: right !important;
  }
}

@media only screen and (max-width: 600px) {
  .phase-wrapper {
    margin: 0 auto;
  }
  .power-readings {
    display: none !important;
  }
  .power-readings-mobile {
    display: block;
    margin-bottom: 8px;
  }
  #phase-card-wrapper .is-size-6 {
    text-align: left !important;
    width: 100%;
  }
}

@media only screen and (max-width: 768px) and (min-width: 493px) {
  #phase-card-wrapper {
    padding-left: 0;
  }
}

@media only screen and (max-width: 450px) {
  .phase-titles,
  .power-readings {
    display: block !important;
  }
  .power-readings-mobile {
    display: none;
  }
  #phase-card-wrapper p.bottom-title {
    font-size: 10px !important;
    height: 19px;
  }
  .phase-wrapper {
    margin: 0;
  }
}
