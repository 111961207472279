.ct-series-a .ct-slice-donut-solid {
  stroke: #2196f3;
  fill: #2196f3;
}

.ct-series-b .ct-slice-donut-solid {
  stroke: #1de9b6;
  fill: #1de9b6;
}

.ct-series-c .ct-slice-donut-solid {
  stroke: #f2bf2b;
  fill: #f2bf2b;
}

.ct-series-d .ct-slice-donut-solid {
  stroke: #202b33;
  fill: #202b33;
}

.Energy-wrapper {
  width: 100%;
  height: auto;
  position: relative;
  padding-bottom: 1rem;
}

.Energy-title {
  color: var(--color-dark-text);
  background: var(--color-bg);
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 12px;
  padding: 10px;
  position: absolute;
  top: -18px;
  /* 66.11 is title text width + its padding / 2 */
  left: calc(50% - 66.11px);
}

h1 {
  font-weight: bold;
  font-size: 25px;
}

h3 {
  font-weight: bold;
  font-size: 18px;
}

h1.energy-reading {
  margin: 0 !important;
}

h1.energy-reading {
  margin: 0 !important;
}

.Energy-wrapper h6 {
  margin: 0 !important;
  text-align: left;
}

.Energy-wrapper h6 span {
  padding-right: 10px !important;
  min-width: 80px;
  display: inline-block;
}

.energy-reading-wrapper {
  align-items: flex-end;
  padding-right: 1rem;
}

.energy-reading span {
  font-size: 12px;
}

.energy-today-reading-wrapper {
  align-items: baseline;
}

.rows {
  display: flex;
  flex-direction: column;
}

.level-item {
  width: 20%;
  /* margin-right: 0px !important; */
}

/* HQ -- Adjust yesterday energy title */
.yesterday-title {
  font-size: 0.9rem;
  font-weight: bold;
}

/* MEDIA QUERIES */

@media only screen and (width: 1920px) {
  /* .Energy-wrapper h6 {
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px !important;
  } */
}

@media only screen and (max-width: 1919px) {
  /* .energy-reading span {
    display: none;
  } */
  /* .Energy-wrapper h6 span {
    padding-right: 10px !important;
  } */
}

@media only screen and (max-width: 1630px) {
  .energy-reading span {
    font-size: 8px;
  }
  .Energy-wrapper h6 span {
    min-width: 55px;
  }
}

@media only screen and (max-width: 1480px) {
  .Energy-wrapper h6 span {
    display: flex;
  }
}

@media only screen and (max-width: 1365px) {
  .Energy-wrapper h6 span {
    min-width: 80px;
  }
}

@media only screen and (max-width: 950px) {
  .Energy-wrapper h6 span {
    display: flex;
  }
}

@media only screen and (max-width: 950px) {
  .energy-reading-wrapper {
    padding-right: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .Energy-wrapper {
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 570px) {
  .Energy-wrapper h6 {
    font-size: 1.2rem !important;
  }
  .Energy-wrapper h6 span {
    min-width: 55px;
  }
}

@media only screen and (max-width: 520px) {
  .Energy-wrapper h6 {
    font-size: 1rem !important;
  }
}

@media only screen and (max-width: 499px) {
  .Energy-wrapper h6 {
    font-size: 0.8rem !important;
  }
}

@media only screen and (max-width: 450px) {
  .Energy-wrapper h6 {
    font-size: 1.5rem !important;
  }
}

@media only screen and (max-width: 426px) {
  h1 {
    font-size: 16px;
  }
  h3 {
    font-size: 14px;
  }
  h5 {
    font-size: 10px;
    font-weight: bold;
  }
  h5.yesterday-title {
    font-size: 10px;
    font-weight: bold;
  }

  /* HQ -- Adjust today/yesterday donut margins in mobile */
  .Energy-wrapper .level-item:last-child {
    margin-right: 0 !important;
  }
  .Energy-wrapper .level-item:last-child h3 {
    font-size: 12px !important;
  }
}
