.card-controls-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.card-controls-box-mobile {
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-color: #bababa;
  background-color: #f8f9d2;
  background-image: linear-gradient(315deg, #f8f9d2 0%, #e8dbfc 74%);
  transition: all 0.5s ease-in-out;
}

.card-controls-box-mobile .card-controls-header {
  border-bottom: 1px solid #4d5862;
  width: 100%;
  padding: 10px 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-controls-box-mobile .card-controls-header p {
  font-size: 14px !important;
  color: var(--color-light-text) !important;
  margin-bottom: 0;
}

.card-controls-box-mobile .card-controls-content-mobile {
  width: 100%;
  padding: 2rem 1rem 1rem 1.5rem;
  min-height: 110px;
}

.card-controls-box-mobile .card-controls-content-mobile .columns {
  padding: 0 !important;
}

.card-controls-box-mobile .card-controls-content-mobile .column {
  padding: 0 0 1rem 1rem !important;
}

.card-controls-box-mobile .card-controls-footer {
  width: 100%;
  padding: 1rem 1.5rem;
  border-top: 1px solid #4d5862;
}

#mobile-anim-wrapper {
  position: fixed;
  left: 0;
  width: 100%;
  transition: all 0.5s ease-in;
  display: none;
  z-index: 100000;
}

#mobile-modal-background {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99999;
}

.card-controls-box-mobile#played {
  animation: none;
  bottom: 0;
}

.card-controls-box-mobile#forward {
  animation: slideUp 0.9s ease-in-out;
  animation-fill-mode: forwards;
}

.card-controls-box-mobile#reverse {
  animation: slideDown 0.9s ease-in-out;
  animation-fill-mode: forwards;
}

.card-controls-box-mobile.dark {
  background-color: #485461;
  background-image: linear-gradient(315deg, #485461 0%, #28313b 74%);
  box-shadow: -4px -12px 18px -5px rgb(0 0 0 / 29%);
  -webkit-box-shadow: -4px -12px 18px -5px rgb(0 0 0 / 29%);
  -moz-box-shadow: -4px -12px 18px -5px rgba(0, 0, 0, 0.29);
}

.card-controls-box-title {
  text-align: center;
  width: 60%;
}

.card-controls-box-mobile .card-controls-box-title {
  text-align: left;
}

.card-controls-box-btn {
  width: 40px;
  height: 40px;
  padding: 10px;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  color: #fff;
  background-color: rgba(10, 10, 10, 0.2);
  border: none;
  border-radius: 50%;
}

.card-controls-box-btn.card-controls-box-btn-cancel {
  margin-left: 2rem;
  cursor: pointer;
}

.card-controls-box-btn.card-controls-box-btn-edit {
  background-color: #3ac6d5;
  width: 170px;
}

.card-controls-box-btn.active {
  background-color: #3ac6d5;
}

a.controls-btn:hover {
  text-decoration: none !important;
}

a.controls-btn.disabled {
  pointer-events: none;
}

@keyframes slideUp {
  0% {
    bottom: -500px;
  }
  100% {
    bottom: 0;
  }
}

@keyframes slideDown {
  0% {
    bottom: 0;
  }
  100% {
    bottom: -500px;
  }
}

.card-controls-footer-mobile .modal-card-foot {
  padding: 20px 0 10px 0;
  border-top: none;
  background-color: transparent;
}

.card-controls-footer-mobile .modal-card-foot .button:not(:last-child) {
  margin-right: 1.5rem;
}

/* MEDIA QUERIES */
@media only screen and (max-width: 1366px) {
  .card-controls-box-title {
    width: 40%;
  }
}

@media only screen and (max-width: 1100px) and (min-width: 769px) and (min-height: 481px) {
  .homepage {
    height: auto;
    min-height: 100vh;
  }
  .homepage nav.nav-tabs {
    padding-left: 0;
  }
  .card-controls-box-title {
    width: 30%;
  }
  .list-view .list-group-left {
    padding-right: 10px !important;
  }
  .list-view .list-group-right {
    padding-left: 10px !important;
  }
}

/* Touchscreen */
@media only screen and (max-width: 800px) and (max-height: 480px) {
  .card-controls-box-title {
    text-align: center;
    width: 27%;
    font-size: 14px;
  }
  .card-controls-box .controls-btn {
    position: relative;
    z-index: 100;
  }
}

@media only screen and (max-width: 768px) {
  #mobile-anim-wrapper {
    display: block;
  }
  .homepage nav.nav-tabs {
    padding-left: inherit;
    display: flex;
    justify-content: center;
  }
  .card-controls-box-btn.card-controls-box-btn-edit {
    width: auto;
  }
  .account-pw .card-controls-box-mobile .card-controls-content-mobile .column {
    padding-right: 1.5rem !important;
  }
  .card-controls-box-desktop {
    display: none;
  }
  .card-controls-box-title {
    width: 100%;
    margin: 3rem 0 2rem 0;
  }
  .card-controls-box-btn-edit {
    position: relative;
    top: -56px;
    width: 50px;
    height: 44px;
    min-width: 50px;
    border-radius: 8px;
  }
  .card-controls-box-btn-edit img {
    padding-right: 0;
  }
  .select-cards-title {
    display: none;
  }
}
