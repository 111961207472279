#config-page .fsp-mobile,
#config-page .fmax-mobile {
  display: none;
}

@media only screen and (max-width: 768px) {
  #config-page .fsp-desktop,
  #config-page .fmax-desktop {
    display: none;
  }
  #config-page .freq-config .row {
    margin-bottom: 3rem;
  }
  #config-page .fsp-mobile,
  #config-page .fmax-mobile {
    display: block;
  }
  #config-page .fsp-mobile {
    margin-bottom: 0 !important;
  }
}

@media only screen and (max-width: 426px) {
  #config-page .freq-config input {
    width: 100%;
  }
}
