.title-wrapper {
  border: 1px   solid #4A4E5C;
  border-bottom-width: 0px;
  position: relative;
}
.card-title {
  color: #6F7281;
  background: #202B33;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 12px;
  position: absolute;
  top: -0.75em;

}
