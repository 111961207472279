.dashboard-page-content {
  /* display: flex;
  align-items: center; */
  /* justify-content: center;
  height: 50vh; */
  padding-top: 0;
  /* overflow: auto; */
}

.content {
  height: 100%;
}

#config-page .dash-back-btn {
  left: 0;
  top: -16px;
}

#dash-card {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
  border: none;
  background-color: #273541;
  min-width: 200px;
  width: 100%;
  height: 100%;
}

#dash-card .card-header {
  padding: 0;
  height: 2rem;
  position: relative;
  border-bottom: none;
}

#dash-card .card-header-title {
  z-index: 10;
  font-size: 12px;
  color: var(--color-light-text);
}

#dash-card .card-header-title .card-header-title-display-icon {
  width: 22px;
  margin-right: 6px;
  position: relative;
  top: -3px;
}

#dash-card .card-header .upper-bg {
  width: 100%;
  height: 50%;
  background-color: #101a22;
  position: absolute;
}

#dash-card .card-header .lower-bg {
  width: 100%;
  height: 50%;
  background-color: #15222b;
  position: absolute;
  top: 50%;
}

#dash-card .card-header-action-icon {
  position: absolute;
  top: 3px;
  right: 10px;
  cursor: pointer;
  z-index: 1000000;
  width: 20px;
}

#dash-card .card-content {
  color: var(--color-light-text);
  font-size: 14px;
  padding: 12px;
  height: 100%;
}

.dashboard-page-content .columns.fullscreen,
.dashboard-page-content .columns #dash-card.fullscreen {
  height: 65vh !important;
  margin-bottom: 100px;
}

#dash-card h2.total-power {
  margin: 0;
}

/* Temp CSS for empty data */
.Cumul-Data-Wrapper {
  min-height: 80px;
}

/* Grid Helpers */
.half-height-wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
}

/*
.full-height-card {
  height: calc(100% + 1.5rem);
}
*/

.half-height-card {
  height: calc(50% - 12px) !important;
}

/* Loading Spinner */
.lds-ring {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  border: 4px solid rgb(0, 170, 208);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: rgb(0, 170, 208) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.device-status-global {
  position: absolute;
  top: 0;
  right: 2rem;
}

.device-status-global .direction-dot {
  right: 55px;
}

#dash-sub-title {
  position: relative;
  left: -0.5vw;
}

/* MEDIA QUERIES */

/* DESKTOP */
@media only screen and (max-width: 1900px) {
  #homepage .columns.columns-last {
    padding-bottom: 80px !important;
  }
}

/* DEAD ZONE TEMP */
@media only screen and (max-width: 1400px) {
  .device-wrapper {
    padding-left: 1.25rem !important;
  }
  #dash-card {
    min-width: 190px;
  }
}

@media only screen and (max-width: 893px) {
  #dash-card {
    min-width: 150px;
  }
}

/* MOBILE */
@media only screen and (max-width: 768px) {
  .half-height-card {
    height: auto !important;
  }
  .device-wrapper {
    padding: 0 1rem !important;
    margin: 0 auto;
  }
  #dash-card {
    border-radius: 6px;
    min-width: auto;
  }
  .dashboard-page-content .columns {
    margin-left: 0;
  }
  #homepage .dashboard-page-content .columns:not(:first-child),
  #homepage .dashboard-page-content .columns:not(:last-child) {
    margin: 0;
    padding: 0;
  }
  .date-time-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .date-time-wrapper .column {
    justify-content: space-evenly !important;
  }
  .device-status-global {
    right: auto;
    left: auto;
    top: -5px;
    margin-left: 36px;
  }
  .device-status-global .direction-dot {
    right: 65px;
  }
  .device-status-global .device-status-text {
    margin-top: 5px;
    display: inline-block;
  }
  #dash-sub-title {
    left: 0;
  }
}

@media only screen and (max-width: 450px) {
  /* All cards fullwidth */
  .full-column-mobile {
    display: block !important;
  }
  .fullwidth-column-mobile {
    width: 100% !important;
  }
  #phase-card-wrapper {
    padding-left: 0;
  }
  #phase-cards-wrapper #dash-card .card-content {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 393px) {
  #dash-card h2.total-power {
    font-size: 1.5rem !important;
  }
  /* .device-status-global {
    margin-left: 46px;
  } */
}

@media only screen and (max-width: 375px) {
  .device-wrapper {
    padding-right: 0 !important;
  }
}

/* Give charts more space in mobile landscape */
/* Needs more research */
@media only screen and (max-width: 1280px) and (orientation: landscape) {
  .dashboard-page-content .columns.fullscreen,
  .dashboard-page-content .columns #dash-card.fullscreen {
    height: 100% !important;
  }
}
