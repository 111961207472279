.admin-console-wrapper {
  padding-top: 2rem;
  margin-bottom: 2rem;
  text-align: center;
  position: relative;
}

h1.admin-console-title {
  font-family: "Montserrat EB", sans-serif;
  font-size: 32px;
  text-align: center;
}

h1 span.admin-console-sub-title {
  font-size: 12px;
  color: #9e9e9e;
}

.admin-dash-wrapper {
  flex-direction: column;
  padding: 0 1rem 3rem 1rem;
  position: relative;
}

.admin-dash-wrapper {
  width: 100%;
}

.admin-dash-wrapper #user-table-header {
  font-family: "Montserrat EB", sans-serif;
  background-color: #182128 !important;
  min-height: 46px;
}

/* Reset native .card class */
#user-list .card {
  border: none !important;
  background: none !important;
  box-shadow: none !important;
}

#user-table-wrapper {
  width: 80vw;
}

#user-list .list-group-item {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 3.5fr 3fr 1.5fr 1.7fr 1.5fr 1.25fr 1fr;
  border-bottom: 1px solid rgb(128 128 128 / 13%);
  background-color: transparent;
  color: var(--color-light-text);
}

#user-list .list-group-item.list-group-item-admin {
  grid-template-columns: 1fr 3.5fr 3fr 1fr;
}

#user-list .list-group-item-item,
#user-list .list-group-item {
  background-color: #1d2831;
  border: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  margin-bottom: 6px;
}

#user-list .list-group-item:not(#user-table-header) > span {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 90%;
  white-space: nowrap;
}

#user-list .user-active-checkbox {
  transform: scale(1.5);
  transform-origin: top;
  position: relative;
  top: -2px;
}

#user-list .user-active-controls-wrapper {
  position: relative;
  left: 25%;
  overflow: visible !important;
}

#user-list .user-delete-wrapper {
  text-align: center;
}

#user-list .user-active-controls {
  position: relative;
  left: 2px;
}

#user-list .user-active-controls img {
  display: inline-block;
  margin-left: 10px;
  width: 20px;
  cursor: pointer;
}

#user-list .user-list-stats {
  font-size: 12px;
  text-align: left;
  padding-top: 12px;
}

#user-list .user-list-stats span {
  font-family: "Montserrat EB", sans-serif;
}

#user-list .action-btn-run-app {
  position: relative;
  height: 35px;
}

#user-list .btn-small {
  width: 35px;
  height: 35px;
  top: 0;
  right: auto;
  margin: 0;
}

#admin-console-wrapper .admin-console-user-info {
  position: absolute;
  right: 2rem;
  display: flex;
  align-items: center;
}

#admin-console-wrapper .user-panel-name {
  color: #3f9eff;
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
}

#admin-console-wrapper .user-back-btn {
  width: 170px;
  height: 30px;
  border: 1px solid #c2c2c2;
  border-radius: 8px;
  font-size: 12px;
  cursor: pointer;
  position: absolute;
  left: 32px;
}

#admin-console-wrapper .user-back-btn.user-back-btn-users {
  left: 32px;
  right: auto;
  margin-top: -8px;
}

#admin-console-wrapper .user-back-btn.user-back-btn-account {
  right: 0;
  left: auto;
}

#admin-console-wrapper .user-back-btn.user-back-btn-account img.app-img {
  filter: invert(49%) sepia(9%) saturate(511%) hue-rotate(193deg);
  width: 18px;
}

#admin-console-wrapper .user-back-btn img {
  display: inline-block;
  margin-right: 10px;
}

#admin-console-wrapper .user-back-btn span.user-icon-mobile,
#admin-console-wrapper .remote-control-enable-mobile {
  display: none;
}

#user-list .list-group-item .mobile-title {
  display: none;
  font-family: "Montserrat EB", sans-serif;
  font-size: 13px;
  margin-right: 25px;
  min-width: 150px;
}

#user-list .delete-user-email-span {
  display: inline-block;
  background: ghostwhite;
  padding: 10px;
  border: 1px solid lightgray;
  margin: 0 2px;
}

.app-in-app-wrapper {
  border-top: 2px solid #999;
  border-radius: 16px;
}

/* Advanced filters */
.filter-icon {
  width: 18px;
  position: relative;
  top: -2px;
}

.search-icon {
  width: 25px;
  position: relative;
  left: 10px;
  cursor: pointer;
}

.search-input {
  font-size: 12px;
  max-width: calc(100% - 50px);
}

#user-list .active-filter.dropdown {
  vertical-align: middle;
  font-family: "Montserrat Thin", sans-serif;
  padding: 0;
  position: relative;
  left: 5px;
  top: -1px;
}

#user-list .active-filter.dropdown .btn {
  padding: 0;
}

.filter-controls-mobile,
.user-list-stats-mobile {
  display: none;
}

#user-list .change-view-title {
  top: -53px;
  right: 195px;
}

#user-list .nav-tabs {
  top: -76px;
}

#user-list .admin-tab-icon {
  border: 2px solid #787878;
  display: inline-block;
  padding: 6px;
  border-radius: 8px;
}

#user-list .admin-tab-icon.active {
  border-color: #1de9b6;
}

#user-list .admin-tab-icon img {
  filter: invert(49%) sepia(9%) saturate(511%) hue-rotate(193deg);
  width: 28px;
  position: relative;
  left: 2px;
}

/* Mobile Modal */

#user-list #mobile-anim-wrapper .row {
  font-family: "Montserrat EB", sans-serif;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

#user-list #mobile-anim-wrapper .row h3 {
  margin-bottom: 0;
  min-width: 100px;
  font-size: 12px !important;
}

#user-list #mobile-anim-wrapper .btn-sort {
  width: 108px;
  height: 35px;
  background: transparent;
  border: 1px solid #767676;
  border-radius: 6px;
  color: #afafaf;
  margin: 5px 10px;
}

#user-list #mobile-anim-wrapper label {
  font-size: 12px;
  color: #6f7281;
}

#user-list #mobile-anim-wrapper .search-input-mobile {
  height: 35px;
}

#user-list #mobile-anim-wrapper .mobile-search-box {
  margin-bottom: 20px;
}

#filters-mobile-modal-content .result-count {
  position: relative;
  top: 6px;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  min-height: 18px;
}

/* Activate Fancy Success */
.fancy-button-wrapper {
  margin: auto;
  position: relative;
}

.frills,
.frills:after,
.frills:before {
  position: absolute;
  background: #9aeac6;
  border-radius: 4px;
  height: 6px;
  z-index: 10000;
}

.frills:after,
.frills:before {
  content: "";
  display: block;
}

.frills:before {
  bottom: 15px;
}

.frills:after {
  top: 15px;
}

.left-frills {
  right: 40px;
  top: 14px;
}

.active .left-frills {
  animation: move-left 0.38s ease-out, width-to-zero 0.38s ease-out;
}

.left-frills:before,
.left-frills:after {
  left: 15px;
}

.active .left-frills:before {
  animation: width-to-zero 0.38s ease-out, move-up 0.38s ease-out;
}

.active .left-frills:after {
  animation: width-to-zero 0.38s ease-out, move-down 0.38s ease-out;
}

.right-frills {
  left: 40px;
  top: 14px;
}

.active .right-frills {
  animation: move-right 0.38s ease-out, width-to-zero 0.38s ease-out;
}

.right-frills:before,
.right-frills:after {
  right: 15px;
}

.active .right-frills:before {
  animation: width-to-zero 0.38s ease-out, move-up 0.38s ease-out;
}

.active .right-frills:after {
  animation: width-to-zero 0.38s ease-out, move-down 0.38s ease-out;
}

.left-frills:before,
.right-frills:after {
  transform: rotate(34deg);
}

.left-frills:after,
.right-frills:before {
  transform: rotate(-34deg);
}

@keyframes move-left {
  0% {
    transform: none;
  }
  65% {
    transform: translateX(-80px);
  }
  100% {
    transform: translateX(-80px);
  }
}
@keyframes move-right {
  0% {
    transform: none;
  }
  65% {
    transform: translateX(80px);
  }
  100% {
    transform: translateX(80px);
  }
}
@keyframes width-to-zero {
  0% {
    width: 38px;
  }
  100% {
    width: 8px;
  }
}
@keyframes move-up {
  100% {
    bottom: 69.75px;
  }
}
@keyframes move-down {
  100% {
    top: 69.75px;
  }
}

/* MEDIA QUERIES */

@media only screen and (max-width: 1366px) {
  #user-table-wrapper {
    width: 90vw;
  }
}

@media only screen and (max-width: 1200px) {
  #user-list .list-group-item {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1016px) {
  #user-list .change-view-title {
    display: none;
  }
  #admin-console-wrapper .user-back-btn.user-back-btn-users {
    left: 16px;
  }
  #admin-console-wrapper .user-back-btn.user-back-btn-account {
    right: -16px;
  }
  h1.admin-console-title {
    font-size: 26px;
  }
}

@media only screen and (max-width: 1000px) {
  #user-list .list-group-item {
    font-size: 10px;
  }
}

@media only screen and (max-width: 900px) {
  #user-list .active-header {
    padding-right: 10px;
    position: relative;
    left: 10px;
  }
  #user-list .active-filter.dropdown {
    left: 5px;
    top: 1px;
  }
}

@media only screen and (max-width: 768px) {
  #user-list .list-group-item {
    grid-gap: 0 10px;
    font-size: 14px;
  }
  #user-list .user-active-checkbox {
    top: 0;
  }
  /* App-in-app Controls */
  h1.admin-console-title {
    margin: 0;
    font-size: 18px;
    text-align: left;
    padding-left: 1.75rem;
    color: #efefef;
    text-transform: uppercase;
    font-family: "Montserrat EB Italic", sans-serif;
  }
  h1 span.admin-console-sub-title {
    font-size: 12px;
  }
  #admin-console-wrapper .user-back-btn,
  #admin-console-wrapper .dash-back-btn {
    right: 20px;
    width: 65px;
    height: 46px;
    padding: 8px;
  }
  #admin-console-wrapper .user-back-btn span.back-to-list {
    display: none;
  }
  #admin-console-wrapper .user-back-btn span.user-icon-mobile {
    display: inline-block;
  }
  #admin-console-wrapper .user-back-btn span.user-icon-mobile img {
    filter: invert(49%) sepia(9%) saturate(511%) hue-rotate(193deg);
  }
  #admin-console-wrapper .user-back-btn.user-back-btn-account img.app-img {
    width: 24px;
    margin-right: 0;
  }
  #admin-console-wrapper .user-panel {
    max-width: 25vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #3f9eff;
  }
  #user-list .active-header {
    width: max-content;
    position: relative;
    left: -16px;
  }
  /* Account App-in-app */
  .app-in-app-wrapper .account-title-wrapper {
    justify-content: center;
  }
}

/* @media only screen and (max-width: 691px) {
  #user-list .list-group-item {
    font-size: 12px;
  }
}

@media only screen and (max-width: 637px) {
  #user-list .list-group-item {
    font-size: 10px;
  }
  #user-list .list-group-item {
    grid-template-columns: 1fr 2.5fr 2fr 1.5fr 1.25fr 1.25fr 1.25fr 1fr;
  }
  #admin-console-wrapper .admin-console-user-info {
    right: 1rem;
  }
  h1 span.admin-console-sub-title {
    display: none;
  }
} */

@media only screen and (max-width: 768px) {
  #user-table-wrapper {
    width: auto;
  }
  #user-list .user-list-stats-desktop {
    display: none;
  }
  .admin-dash-wrapper {
    padding-bottom: 7rem;
  }
  h1.admin-console-title {
    width: 200px;
  }
  h1 span.admin-console-sub-title {
    display: inline-block;
  }
  #user-list .nav-tabs {
    top: -145px;
  }
  .search-icon {
    top: 3px;
  }
  .filter-controls-mobile {
    display: block;
    margin-top: 8px;
    margin-bottom: 25px;
    border-bottom: 2px solid #141e26;
    display: flex;
    justify-content: flex-end;
    position: relative;
  }
  .filter-controls-mobile .mobile-filter-dot {
    color: limegreen;
    font-weight: bold;
    text-decoration: none;
    visibility: visible;
    font-size: 40px;
    position: absolute;
    right: 75px;
    top: -30px;
  }
  .filter-controls-mobile div {
    font-size: 12px;
    color: #3f9eff;
    text-decoration: underline;
    padding: 4px;
    cursor: pointer;
  }
  .filter-controls-mobile .filter-controls-mobile-arrow-down {
    filter: invert(49%) sepia(9%) saturate(511%) hue-rotate(193deg);
    transform: rotate(-90deg);
    width: 14px;
    position: relative;
    left: -4px;
  }
  .user-list-stats-mobile {
    display: block;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #202b33;
    padding: 10px 1rem;
    -webkit-box-shadow: 0px -5px 8px -6px rgb(0 0 0 / 25%);
    -moz-box-shadow: 0px -5px 8px -6px rgb(0 0 0 / 25%);
    box-shadow: 0px -5px 8px -6px rgb(0 0 0 / 25%);
  }
  #user-list .list-group-item,
  #user-list .list-group-item.list-group-item-admin {
    grid-template-columns: repeat(auto-fill, 85vw);
    grid-gap: 12px 10px;
    font-size: 13px;
    border: 1px solid #6f7281;
    padding: 18px;
    border-radius: 8px;
  }
  #user-list .list-group-item.list-group-item-admin {
    padding: 24px;
  }
  #user-list .card {
    width: 90%;
    margin: 0 auto 20px auto;
  }
  .admin-dash-wrapper #user-table-header {
    display: none;
  }
  #user-list .list-group-item .mobile-title {
    display: inline-block;
  }
  #user-list .user-active-controls-wrapper {
    left: 0;
    text-align: left;
  }
  #user-list .user-active-controls {
    left: 20px;
  }
  #user-list .user-active-checkbox {
    left: 7px;
  }
  #user-list .user-delete-wrapper {
    text-align: left;
  }
  #user-list .user-delete-wrapper img {
    margin-left: 1px;
  }
  #user-list .action-btn-run-app {
    justify-content: flex-start;
  }
  #user-list .btn-small {
    left: 170px;
  }
  #user-list .delete-user-email-span {
    padding: 6px;
  }
  #admin-console-wrapper .user-back-btn.user-back-btn-account {
    right: 6px;
  }
}

@media only screen and (max-width: 461px) {
  #user-list #mobile-anim-wrapper #tabbar.row {
    flex-direction: column;
  }
}

@media only screen and (max-width: 440px) {
  h1.admin-console-title {
    font-size: 18px;
  }
  #user-list .list-group-item .mobile-title {
    min-width: 100px;
  }
  #user-list .btn-small {
    left: 122px;
  }
}

@media only screen and (max-width: 450px) {
  h1.admin-console-title {
    width: 200px;
    padding-left: 1.5rem;
    font-size: 16px;
    position: relative;
    top: -4px;
  }
  #user-list .nav-tabs {
    top: -145px;
  }
}
