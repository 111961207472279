#config-page .vsp-mobile,
#config-page .vmax-mobile {
  display: none;
}

@media only screen and (max-width: 768px) {
  #config-page .vsp-desktop,
  #config-page .vmax-desktop {
    display: none;
  }
  #config-page .voltage-config .row {
    margin-bottom: 3rem;
  }
  #config-page .vsp-mobile,
  #config-page .vmax-mobile {
    display: block;
  }
  #config-page .vsp-mobile {
    margin-bottom: 0 !important;
  }
}

@media only screen and (max-width: 426px) {
  #config-page .voltage-config input {
    width: 100%;
  }
}
