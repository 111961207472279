#modal-content-wrapper .flex-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  font-weight: 700;
}

#modal-content-wrapper .columns {
  padding: 10px 0;
}

#modal-content-wrapper p {
  font-size: 11px;
  margin-bottom: 0;
}

#modal-content-wrapper .input-err-msg-wrapper p {
  font-size: 11px;
  text-align: left;
  margin-left: 1rem;
}

.eye-icon-account {
  right: -20px !important;
  top: 18px !important;
}

#modal-content-wrapper label#pw-label-is-admin {
  color: orangered !important;
}

@media only screen and (max-width: 768px) {
  .eye-icon-account {
    right: -6px !important;
    top: 8px !important;
  }
  #modal-content-wrapper .input-err-msg-wrapper p {
    margin-left: 0;
  }
}
